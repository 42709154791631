<template>
  <section class="sign-in mx-auto">
    <div class="container-fluid h-100">
      <div class="row align-items-center h-100">
        <div
          class="col-lg-5 col-md-5 d-lg-block d-md-block d-none h-100 bg-login"
          style="padding: 0 !important; height: 100vh !important"
        ></div>
        <div class="login-right col-lg-7 col-md-7 col-sm-12 h-100">
          <div class="card border-0">
            <div class="card-body">
              <form action="">
                <div class="container mx-auto">
                  <div class="mb-lg-4 mb-md-4 mb-4">
                    <a class="navbar-brand" href="../index.html">
                      <img
                        src="../../assets/images/logo.png"
                        alt="logo-kemnaker"
                        width="154px"
                        height="53px"
                      />
                    </a>
                  </div>
                  <h2 class="fw-bold color-palette-1 mb-2">
                    Sistem Pengelolaan <br />
                    Informasi Jabatan
                  </h2>
                  <p class="text-lg color-palette-1 m-0">
                    Masuk untuk memulai sesi Anda
                  </p>
                  <div class="mt-lg-4 mt-md-3 mt-4 mb-3">
                    <label
                      for="username"
                      class="form-label fw-medium text-lg mb-2"
                      >Username</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="username"
                      name="username"
                      aria-describedby="username"
                    />
                  </div>
                  <div class="mb-3">
                    <label
                      for="password"
                      class="form-label text-lg fw-medium color-palette-1 mb-10"
                      >Password</label
                    >
                    <input
                      type="password"
                      class="form-control form-control-sm"
                      id="password"
                      name="password"
                      aria-describedby="password"
                    />
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="mb-3 form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                      />
                      <label class="form-check-label" for="exampleCheck1"
                        >Ingat Saya</label
                      >
                    </div>
                    <a
                      class="d-inline text-decoration-none fw-semibold"
                      href="../src/sign-up.html"
                      role="button"
                      >Lupa Password</a
                    >
                  </div>
                  <div class="d-flex flex-column mx-auto text-center mt-4">
                    <button class="btn btn-primary">Masuk</button>
                    <span class="d-inline mt-3"
                      >Belum punya akun?
                      <a
                        class="d-inline text-decoration-none fw-semibold"
                        href="../src/sign-up.html"
                        role="button"
                        >Daftar Sekarang</a
                      >
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>
  @import url('../../assets/styles/sign-in.css');
</style>